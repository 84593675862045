import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
// Components
import { Box, Button, Flex, Text } from '../../../atoms'
// Types
import { PricingPlanProps } from '../../../types/pricing'
import ContentMd from '../../commons/ContentMd'
import { BoxWithDot, InputBox, InputCurrency, InputNumber } from './PricingPlan.styled'
import { blockInvalidChar, formatPlanPrice, getPriceFromMonthlyCost } from './PricingPlan.utils'

const PricingPlan: React.FC<PricingPlanProps> = ({
  name,
  tag,
  description,
  descriptionHelp,
  displayPrice,
  defaultPrice,
  useFormula,
  freeLabel,
  contactLabel,
  costInputPlaceholder,
  priceFormat,
  featuresLabel,
  featuresList,
  button,
}) => {
  const [price, setPrice] = useState<number>(defaultPrice ? defaultPrice : 0)
  const [cloudMonthlyCost, setCloudMonthlyCost] = useState('')
  const [inputFocused, setInputFocused] = useState(false)

  const renderers = useMemo(() => {
    return {
      li: (props: PropsWithChildren<any>) => {
        return (
          <BoxWithDot as={'li'} position={'relative'} my={4} dotColor={'purpleBlue'} ordered={props.ordered}>
            <Text as={'span'} fontSize={2} lineHeight={1.5} {...props} color={'dark'} />
          </BoxWithDot>
        )
      },
    }
  }, [])

  useEffect(() => {
    if (useFormula) {
      if (cloudMonthlyCost === '') {
        setPrice(0)
      } else {
        setPrice(getPriceFromMonthlyCost(parseFloat(cloudMonthlyCost)))
      }
    }
  }, [useFormula, cloudMonthlyCost])

  return (
    <Flex
      position={'relative'}
      flexDirection={'column'}
      alignItems={'stretch'}
      height={'100%'}
      borderRadius={'regular'}
      borderColor={'dark'}
      borderWidth={3}
      borderStyle={'solid'}
      bg={'white'}
      overflow={'hidden'}
      zIndex={1}
    >
      <Box flex={'0 0 auto'} minHeight={['auto', '280px']} bg={'greyLight'} pb={[5, 5, 7]}>
        {/* PLAN NAME */}
        <Flex justifyContent={'center'} mb={6}>
          <Flex
            flex={'0 0 auto'}
            justifyContent={'center'}
            alignItems={'center'}
            flexWrap={'wrap'}
            bg={'dark'}
            py={5}
            px={[24, 24, 32]}
            borderBottomLeftRadius={'regular'}
            borderBottomRightRadius={'regular'}
            mt={'-3px'}
          >
            <Text fontSize={4} fontWeight={'semi'} lineHeight={1.2} color={'white'}>
              {name}
            </Text>
            {!!tag && (
              <Box display={'inline-flex'} ml={2} p={1} bg={'purple'} borderRadius={'default'}>
                <Text
                  as={'span'}
                  textTransform={'uppercase'}
                  fontSize={0}
                  fontWeight={'semi'}
                  lineHeight={1.2}
                  color={'white'}
                >
                  {tag}
                </Text>
              </Box>
            )}
          </Flex>
        </Flex>

        <Box px={[4, 5, 7]}>
          {displayPrice ? (
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Text fontSize={40} lineHeight={1.2} fontWeight={'semi'} fontFamily={'hoves'}>
                {formatPlanPrice(useFormula, priceFormat, price, contactLabel, freeLabel)}
              </Text>
              {((!!description && !useFormula) || (!!description && useFormula && price === 0)) && (
                <Box flex={'0 0 auto'} maxWidth={useFormula ? 88 : 74} ml={4}>
                  <Text fontSize={15} lineHeight={1.2} fontWeight={'semi'}>
                    {description}
                  </Text>
                </Box>
              )}
            </Flex>
          ) : (
            <Text fontWeight={'semi'} lineHeight={1.6} textAlign={'center'}>
              {description || ''}
            </Text>
          )}
          {/* Description help */}
          {!!descriptionHelp && (
            <Box mt={3}>
              <Text textAlign={'center'} fontSize={1} opacity={0.56}>
                {descriptionHelp}
              </Text>
            </Box>
          )}
          {useFormula && (
            <Box mt={6}>
              <InputBox className={inputFocused ? 'cost-focused' : undefined}>
                {cloudMonthlyCost !== '' && (
                  <InputCurrency color={inputFocused ? 'purpleBlue' : 'dark'}>
                    <Text fontSize={2} fontWeight={'medium'} color={'inherit'}>
                      {'$'}
                    </Text>
                  </InputCurrency>
                )}
                <InputNumber
                  type={'number'}
                  value={cloudMonthlyCost}
                  min={0}
                  max={1000000}
                  placeholder={costInputPlaceholder || 'Enter your monthly cost'}
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => setCloudMonthlyCost(e.target.value)}
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </InputBox>
            </Box>
          )}
        </Box>
      </Box>
      <Flex flex={'1'} flexDirection={'column'} alignItems={'stretch'} p={[4, 5, 7]}>
        {/* FEATURES */}
        <Box flex={'1'}>
          {!!featuresLabel && <Text fontWeight={'semi'}>{featuresLabel}</Text>}
          {!!featuresList && (
            <Box mt={6}>
              <ContentMd content={featuresList} renderers={renderers} />
            </Box>
          )}
        </Box>
        {/* BUTTON */}
        {button && (
          <Flex flex={'0 0 auto'} justifyContent={'center'} mt={[4, 5, 6]}>
            <Button {...button} />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default PricingPlan
