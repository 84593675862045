import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import React from 'react'
import { Box, BoxProps } from '../../../atoms'

export const InputBox = styled.div`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.dark};
  border: 2px solid ${({ theme }) => theme.colors.dark};
  border-radius: ${({ theme }) => theme.radii.small}px;
  background-color: ${({ theme }) => theme.colors.white};

  &.cost-focused {
    border-color: ${({ theme }) => theme.colors.purpleBlue};
    color: ${({ theme }) => theme.colors.purpleBlue};
  }
`

export const InputCurrency = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  width: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InputNumber = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  outline: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 20px;
  padding-left: ${(props) => (props.value !== '' ? '32px' : '20px')};
  border: none;
  background: transparent;
  transition: all 0.15s ease;

  &:focus {
    color: ${({ theme }) => theme.colors.purpleBlue};
  }

  &::placeholder {
    color: ${rgba(12, 10, 48, 0.64)};
    font-weight: 300;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const BoxWithDot: React.FC<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }> = styled(
  Box
)<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }>`
  ${({ ordered }) =>
    !ordered &&
    css`
      &:before {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgOEw3LjIyMjIyIDEyTDEzIDQiIHN0cm9rZT0iIzcxMDFGRiIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        top: 4px;
        left: -24px;
      }
    `}
`
