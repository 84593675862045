import React from 'react'
// Types
import { SectionBaseProps, SectionPricing } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Text } from '../../../atoms'
import PricingPlan from '../../pricing/PricingPlan'

export interface PricingProps extends SectionBaseProps {
  data: SectionPricing
}

const Pricing: React.FC<PricingProps> = ({ data }) => {
  const { id, sectionId, headline, content, whiteText, plans, backgroundColor } = data

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      py={[80, 80, 120]}
      backgroundColor={backgroundColor.hex}
    >
      <Grid>
        <Box mb={[10, 100, 120]}>
          <Row center={'xs'}>
            <Col xs={12} sm={10} md={8}>
              <Heading textAlign={['left', 'center']} as={'h3'} color={whiteText ? 'white' : 'dark'}>
                {headline}
              </Heading>
              {!!content && (
                <Box mt={[4, 5, 6]}>
                  <Text whiteSpace={'pre-line'} textAlign={['left', 'center']} color={whiteText ? 'white' : 'dark'}>
                    {content}
                  </Text>
                </Box>
              )}
            </Col>
          </Row>
        </Box>
        <Row center={'xs'}>
          {plans.map((pp, i) => (
            <Col key={pp.id} xs={12} sm={4}>
              <Box position={'relative'} height={['auto', '100%']} mb={i + 1 < plans.length ? [7, 0] : 0}>
                <PricingPlan {...pp} />
              </Box>
            </Col>
          ))}
        </Row>
      </Grid>
    </Box>
  )
}

export default Pricing
