export const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

export const getPriceFromMonthlyCost = (cost: number): number => {
  const minCost = 5000
  const maxCost = 100000

  if (cost <= minCost || cost >= maxCost) {
    if (cost <= minCost) return 0
    if (cost >= maxCost) return -1
  }

  return ((cost - 5000) / 1000) * 17
}

export const formatPlanPrice = (
  useFormula: boolean,
  priceFormat: string,
  price: number,
  contactLablel: string,
  freeLabel: string
): string => {
  const pfmt = priceFormat.includes('{price}') ? priceFormat : '${price}/m'

  if (price < 0) return contactLablel
  if (price === 0 && useFormula) return freeLabel

  const formatNumber = price % 1 ? price.toFixed(2) : price.toString()
  return pfmt.replace('{price}', formatNumber)
}
